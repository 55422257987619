@tailwind base;
@tailwind components;
@tailwind utilities;

body {
  margin: 0;
  font-family: -apple-system, BlinkMacSystemFont, 'Segoe UI', 'Roboto', 'Oxygen',
    'Ubuntu', 'Cantarell', 'Fira Sans', 'Droid Sans', 'Helvetica Neue',
    sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, 'Courier New',
    monospace;
}


.hero-container {
  /* background: linear-gradient(
    -45deg,
    transparentize(#ee7752, 0.2),
    transparentize(#e73c7e, 0.2),
    transparentize(#23a6d5, 0.2),
    transparentize(#23d5ab, 0.2)
  ); */
  opacity: 0.9;
  background-size: 200% 200%;
  animation: gradient-animation 5s ease infinite;
  height: 100%;
  width: 100%;
  mix-blend-mode: multiply;
}

@keyframes gradient-animation {
  0% {
    background-position: 0% 50%;
  }
  50% {
    background-position: 100% 50%;
  }
  100% {
    background-position: 0% 50%;
  }
}
